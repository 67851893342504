<template>
  <div class="text-scroll">
    <model-title
      title="滚动文字"
      :primary="primary"
      :lock="false"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>
    <van-notice-bar
      color="#333"
      background="#edfaff"
      left-icon="volume-o"
      :text="
        value[0]
          ? value[0].words
          : '滚动文字滚动文字滚动文字滚动文字滚动文字滚动文字滚动文字'
      "
    />
  </div>
</template>

<script>
import modelTitle from "./model-title";
export default {
  name: "textScroll",
  components: { modelTitle },
  props: {
    primary: {
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [
          { words: "滚动文字滚动文字滚动文字滚动文字滚动文字滚动文字滚动文字" },
        ];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.text-scroll {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  /deep/ .van-notice-bar__left-icon {
    color: #ff5200;
    font-weight: bold;
  }
}
</style>