<template>
  <div class="ad-swiper">
    <model-title
      title="轮播广告"
      :primary="primary"
      :lock="lock"
      :gold="gold"
      :unlockId="12"
      :modulesType="1"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <van-swipe class="my-swipe" indicator-color="#666" v-if="value.length">
      <van-swipe-item v-for="(item, index) in value" :key="index">
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.picUrl"
          fit="cover"
        ></van-image>
      </van-swipe-item>
    </van-swipe>

    <van-swipe class="my-swipe" indicator-color="#666" v-else>
      <van-swipe-item v-for="item in 3" :key="item">
        <div style="position: relative">
          <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
          <div class="add-ad-pic">添加广告图片</div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "adSwiper",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[4].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[4].cost
        : 0;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.ad-swiper {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .primary {
    width: 90vw;
    height: 37vw;
  }
  .small {
    width: 44vw;
    height: 18vw;
  }
  .add-ad-pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #333;
  }
  /deep/ .van-swipe__indicator {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>