<template>
  <div class="ad-big-slide">
    <model-title
      title="大图左滑广告"
      :primary="primary"
      :lock="lock"
      :gold="gold"
      :unlockId="14"
      :modulesType="1"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div style="display: flex; overflow: auto" v-if="value.length">
      <div class="slide-item" v-for="(item, index) in value" :key="index">
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.picUrl"
          fit="cover"
        ></van-image>
      </div>
    </div>

    <div style="display: flex; overflow: auto" v-else>
      <div class="slide-item" v-for="item in 4" :key="item">
        <div style="position: relative">
          <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
          <div class="add-ad-pic">添加广告图片</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "adBigSlide",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[6].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[6].cost
        : 0;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.ad-big-slide {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .slide-item {
    margin-right: 3vw;
    &:last-of-type {
      margin-right: 0;
    }
    .primary {
      width: 48vw;
      height: 36vw;
    }
    .small {
      width: 24vw;
      height: 18vw;
    }
    .add-ad-pic {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 10px;
      color: #333;
    }
  }
}
</style>