<template>
  <div class="navigation-slide">
    <model-title
      title="左滑图片导航"
      :primary="primary"
      :lock="lock"
      :gold="gold"
      :unlockId="9"
      :modulesType="1"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div style="display: flex; overflow: auto" v-if="value.length">
      <div
        class="slide-item"
        v-for="(item, index) in value"
        :key="index"
        :style="primary ? 'margin-right: 3vw;' : 'margin-right: 2vw;'"
      >
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.picUrl"
          fit="cover"
        ></van-image>
        <div class="item-bottom">
          <div style="color: #333; margin-top: 5px; text-align: center">
            {{ item.navigationName }}
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; overflow: auto" v-else>
      <div
        class="slide-item"
        v-for="item in 6"
        :key="item"
        :style="primary ? 'margin-right: 3vw;' : 'margin-right: 2vw;'"
      >
        <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
        <div class="item-bottom">
          <div style="color: #333; margin-top: 5px; text-align: center">
            导航{{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "navigationSlide",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    lock() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[2].lock
        : true;
    },
    gold() {
      return this.$store.state.fitment.modules.length
        ? this.$store.state.fitment.modules[2].cost
        : 0;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.navigation-slide {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .slide-item {
    .primary {
      width: 16.5vw;
      height: 16.5vw;
    }
    .small {
      width: 8vw;
      height: 8vw;
    }
    .item-bottom {
      font-size: 10px;
    }
  }
}
</style>