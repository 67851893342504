<template>
  <div class="ad-big-pic">
    <model-title
      title="大图广告"
      :primary="primary"
      :lock="false"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <van-image
      v-if="value.length"
      :class="primary ? 'primary' : 'small'"
      :src="value[0].picUrl"
      fit="cover"
    ></van-image>

    <div style="position: relative" v-else>
      <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
      <div class="add-ad-pic">添加广告图片</div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "adBigPic",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.ad-big-pic {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .primary {
    width: 90vw;
    height: 120vw;
  }
  .small {
    width: 44vw;
    height: 58.5vw;
  }
  .add-ad-pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #333;
  }
}
</style>