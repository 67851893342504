<template>
  <div class="navigation-pic">
    <model-title
      title="图片导航"
      :primary="primary"
      :lock="false"
      @choose="$emit('choose')"
      @delete="$emit('delete')"
      @up="$emit('up')"
      @edit="$emit('edit')"
      @insert="$emit('insert')"
    ></model-title>

    <div style="display: flex" v-if="value.length">
      <div
        class="pic-item"
        v-for="(item, index) in value"
        :key="index"
        :style="primary ? 'margin-right: 2vw;' : 'margin-right: 1vw;'"
      >
        <van-image
          :class="primary ? 'primary' : 'small'"
          :src="item.picUrl"
          fit="cover"
        ></van-image>
        <div class="item-bottom">
          <div style="color: #333; margin-top: 5px; text-align: center">
            {{ item.navigationName }}
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex" v-else>
      <div
        class="pic-item"
        v-for="item in 5"
        :key="item"
        :style="primary ? 'margin-right: 2vw;' : 'margin-right: 1vw;'"
      >
        <model-pic :class="primary ? 'primary' : 'small'"></model-pic>
        <div class="item-bottom">
          <div style="color: #333; margin-top: 5px; text-align: center">
            导航{{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modelTitle from "./model-title";
import modelPic from "./model-pic";
export default {
  name: "navigationPic",
  components: { modelTitle, modelPic },
  props: {
    primary: {
      default: false,
    },
    value: {
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.navigation-pic {
  background-color: #fff;
  border-radius: 2px;
  margin: 2vw 1vw;
  padding: 2vw;
  .pic-item {
    .primary {
      width: 16.5vw;
      height: 16.5vw;
    }
    .small {
      width: 8vw;
      height: 8vw;
    }
    .item-bottom {
      font-size: 10px;
    }
  }
}
</style>